:root {
  --gray-dark: #111111;
  --gray-medium: #333333;
  --gray-light: #555555;
  --gray-lighter: #777777;
  --gray-lightest: #aaaaaa;
  --green-active: hsl(100, 20%, 30%);
  --magenta-active: hsl(300, 20%, 30%);
  --orange-active: hsl(30, 20%, 30%);
  --blue-start: hsl(200, 70%, 10%);
  --blue-start-lighter: hsl(200, 70%, 20%);
  --border-radius-big: 100vw;
  --border-radius-small: 0.5vw;
  --duration-hover: 0.2s;
  --distance-hover-pos: 15%;
  --distance-hover-neg: calc(-1 * var(--distance-hover-pos));
  --id-red: #e81224;
  --id-blue: #0078d7;
  --id-yellow: #fff100;
  --id-green: #16c60c;
  --id-red-dark: #6c2026;
  --id-blue-dark: #203f7c;
  --id-yellow-dark: #6c6c00;
  --id-green-dark: #005c00;
  flex: 1;
}

* {
  padding: 0px;
  margin: 0px;
  font-weight: 300;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  overflow: hidden;
}

html {
  padding: 0px;
  margin: 0px;
}

body {
  background-color: black;
  color: white;
  display: flex;
}

html,
body {
  height: 100%;
  margin: 0;
}

h1 {
  font-size: 2vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
}

h2 {
  margin-top: 1.5vw;
  margin-bottom: 0.7vw;
}

.over-div {
  aspect-ratio: 16 / 9;
  max-width: 100vw; /* Maximum width is 100% of the viewport width */
  max-height: 100vh; /* Maximum height is 100% of the viewport height */
  width: auto; /* Allows the width to adjust based on the height while maintaining aspect ratio */
  height: auto; /* Allows the height to adjust based on the width while maintaining aspect ratio */
  margin: auto; /* Centers the div in the viewport */
  position: absolute; /* Takes the div out of the normal document flow */
  top: 0;
  bottom: 0; /* These settings, combined with left and right, center the div vertically */
  left: 0;
  right: 0;
}

#phaser-container {
  position: absolute;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: auto;
  z-index: 0;
}

#root {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.bg-trans {
  background-color: rgba(0, 0, 0, 0);
}

.bg-black {
  background-color: black;
}

.top-bar-eye-open {
  position: absolute;
  top: 0%;
  border: 0%;

  height: 5%;
  width: 100%;

  padding: 0% 3% 0% 3%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  transition: 2s ease;
  /* box-shadow: 0vw 0vw 1vw 1vw rgba(0, 0, 0, 1); */
}

.top-bar-eye-open > .link-tag,
.top-bar-eye-open > .eye-mark {
  border: 0;

  color: white;
  filter: opacity(1);
  transition: 0.1s ease;
}

.top-bar-eye-closed {
  position: absolute;
  top: 0%;
  border: 0%;

  height: 5%;
  width: 100%;

  padding: 0% 3% 0% 3%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  transition: 2s ease;
}

.top-bar-eye-closed:hover {
  background-color: black;
  border: 0;
  transition: 0.1s ease;
}
#wcl {
  position: relative;
  width: 50%;
  min-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
#wcl > a > span {
  position: relative;
  margin-left: 3%;
}

.working-controller {
  width: 100%;
  padding: 1%;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  filter: opacity(1);
  transition: 0.1s ease;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.working-controller:hover {
  color: black;
  background-color: rgba(255, 255, 255, 255);
  border: 0;
  transition: 0.1s ease;
}

.top-bar-eye-closed:hover > .link-tag,
.top-bar-eye-closed:hover > .eye-mark {
  border: 0;
  color: white;
  filter: opacity(1);
  transition: 0.1s ease;
}
.eye-mark {
  height: 100%;
  justify-self: flex-start;
  filter: opacity(0.3);
  transition: 2s ease;
  cursor: pointer;
}

#link {
  border: 0;
  margin: 0;
  padding: 0;
}

.link-tag {
  background-color: transparent;
  transition: 0s ease;
  border: 0;
  padding: 0 2%;
  margin: 0;
  color: transparent;
  font-size: 1.5vw;
  transition: 2s ease;
  cursor: pointer;
}

.horiz {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 2%;
}
.horiz-item-small {
  flex: 1;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.horiz-item-big {
  flex: 5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  /* allow user to highlight text normall */
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.horiz-item-big > p {
  margin: 0;
  padding: 0;
  /* allow user to highlight text normall */
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  color: white;
  font-size: 1vw;
}

.horiz-item-big::-webkit-scrollbar {
  /* width: 1vw; */
  background: var(--gray-medium);
  cursor: ns-resize;
}
.horiz-item-big::-webkit-scrollbar-track:hover {
  background: var(--gray-light);
}
.horiz-item-big::-webkit-scrollbar-thumb {
  background: var(--gray-lighter);
}
.horiz-item-big::-webkit-scrollbar-thumb:hover {
  background: white;
}
.scroller {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  /* flex-direction: column-reverse; */
  justify-content: flex-start;
  align-items: flex-start;

  list-style: none;
  /* flex-wrap: nowrap; */
  overflow-y: scroll;
  overflow-x: hidden;
  /* direction: rtl; */
  /* cursor: all-scroll; */
  padding: 1%;
  /* border: 3px solid white; */
  background-color: #0a0a1a;
}
.scroller::-webkit-scrollbar {
  width: 1vw;
  background: var(--gray-medium);
  cursor: ns-resize;
}
.scroller::-webkit-scrollbar-track:hover {
  background: var(--gray-light);
}
.scroller::-webkit-scrollbar-thumb {
  background: var(--gray-lighter);
}
.scroller::-webkit-scrollbar-thumb:hover {
  background: white;
}
.text-small {
  min-height: 1.8vw;
  font-size: 1vw;
  color: white;
  margin: 0;
  margin-top: 0.2%;
  padding: 0;
  text-align: center;
}
.text-white {
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
}
.popup {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  border: 0px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 4vw 1vw 0.7vw 1vw;

  z-index: 2;
}
.popup > h1 {
  margin-top: 0.7vw;
  margin-bottom: 0vw;
  height: 7vw;
}

.popup > p {
  font-size: 1.5vw;
  color: white;
}
.popup > ul > li {
  font-size: 1vw;
  color: white;
}
.popup > a {
  padding: 1.5% 4%;
  font-size: 1.3vw;
  margin-bottom: 1%;
  color: white;
}
.popup > div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  color: white;
}

.rules-top {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
}
.rules-col {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background-color: white; */

  /* flex-wrap: wrap; */
  /* cursor: pointer; */
}

.about-image-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 15%;
  margin-top: 5%;
}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-image-pixelated {
  /* CRISP EDGES */
  image-rendering: pixelated;
}

#home-debug {
  width: 100%;

  /* height: 4vw; */
  text-transform: uppercase;

  /* border-radius: 0.5vw; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* margin-top: 0.25vw; */
  /* margin-bottom: 0.25vw; */
  padding: 0.5vw 0.5vw 0.2vw 0.5vw;
  text-align: center;
}

#home-debug:hover {
  background-color: var(--gray-medium);
  color: white;
}
#home-debug:active {
  background-color: var(--gray-light);
  color: white;
}

#option-debug {
  width: 33.333333%;
  /* height: 5vw; */

  /* border-radius: 0.5vw; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* margin: 0.5vw; */
  padding: 0.5vw 1vw 0.2vw 1vw;
  text-transform: uppercase;
  text-align: center;
}

#option-debug:hover {
  background-color: var(--gray-medium);
  color: white;
}
#option-debug:active {
  background-color: var(--gray-light);
  color: white;
}

.option-debug-true {
  color: black;
  background-color: white;
}
.option-debug-false {
  color: rgba(255, 255, 255, 0.5);
  /* background-color: black; */
}

.move {
  margin-bottom: 0.5vw;
}
#keyboard {
  /* width: 45vw; */
  background-color: rgba(128, 128, 128, 0.1);
  padding: 1.5vw 1vw 1vw 1vw;
  margin: 0vw 0vw 0.5vw 0vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#keybaord-top,
#keyboard-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5vw;
}
#keyboard-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 1vw;
}
#keyboard-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 1vw;
}
#keyboard-left > p,
#keyboard-right > p {
  margin: 0;
  margin-top: 0.3vw;
  padding: 0;
  font-size: small;
}

/* --- Controls Popup Styles --- */

/* Wrapper for the entire “show controls” content */
.controls-wrapper {
  /* Let the popup’s .popup handle sizing; just fill its space */
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 2vw;
  margin: 2vw auto; /* Some space around the content */
}

/* Left side: SNES image + moves list */
.controls-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* The SNES controller image */
.snes {
  /* width: 10vw; */
  margin-bottom: 1vw;
  border: none; /* Remove border if not needed */
}

/* Wrapper for all moves */
.moves-list {
  width: 100%;
  margin-top: 1vw;
}

/* Individual move row */
.move {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5vw;
  padding: 0.5vw;
  border-radius: var(--border-radius-small);
  background-color: rgba(128, 128, 128, 0.1);
}

/* Right side: Keyboard info */
.controls-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Each group of keyboard mappings */
.keyboard-group {
  width: 100%;
  margin-bottom: 1vw;
  padding: 1vw;
  border-radius: var(--border-radius-small);
  background-color: rgba(128, 128, 128, 0.1);
}

/* A small header for each group (like "Keyboard W, A, S, D ...") */
.keyboard-group-header {
  text-align: center;
  font-size: 1.2vw;
  font-weight: bold;
  margin-bottom: 2vw;
}

/* Individual keyboard rows */
.keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5vw;
}

h5 {
  font-size: 1.1vw;
}
#rules-web-gif {
  width: 50%;
  margin-bottom: 1%;
}
.rules-outline-web {
  width: auto;
  /* max-width: 100vw; */
  /* height: 100%; */
  /* max-height: 100%;  */
  background-color: rgb(0, 0, 0);
  margin: 5%;
  padding: 0% 0% 2% 0%;
  /* border-radius: 10px; */
  /* border: 10px solid rgb(185, 122, 87); */
  /* border: 6px solid rgb(65, 58, 54); */
  border: 6px solid rgb(230, 206, 193);
  background-color: rgb(255, 255, 255);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hide;
  cursor: pointer;
}
.rules-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.rules-italic {
  font-style: italic;
}
.rules-li {
  width: 45%;
  min-height: 130px;
  margin: 1%;
  padding: 1% 0% 5% 3%;
  border: 3px solid black;
  background-color: rgb(230, 206, 193);
}
.rules-big {
  font-size: 1.5vw;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 900;
}
.rules-small {
  font-size: 1vw;
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
}
.rules-end {
  height: 100%;
  padding-top: 5%;
  padding-right: 5%;
  align-self: flex-end;
  justify-self: flex-end;
  text-align: end;
}
.rules-outline-n64 {
  width: auto;
  margin: 5%;
  background-color: rgb(255, 255, 255);
  border: 6px solid rgb(230, 206, 193);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hide;
  cursor: pointer;
}
.dark-span {
  color: rgba(255, 255, 255, 0.25);
}

#RulesN64Image {
  /* height: 100%; */
  width: 100%;
  overflow: hide;
}
.start-class-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.player-choices {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.player-choices-left {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: scroll;
  max-height: 80vh; /* Set a maximum height, adjust as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrollbar if not needed */
  margin-left: 1vw;

  /* &::-webkit-scrollbar {
    display: none;
  } */
}
.player-choices-right {
  flex: 2.6;
  height: 100%;
  padding: 0vw 0.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  overflow: visible;
}

.player-choice {
  flex: 1;
  height: 100%;
  /* padding: 0% 0.5vw 0% 0.5vw; */
  /* margin-bottom: 0; */
  font-size: 3vw;
  margin: 0vw 0.5vw;
  /* margin-left: 0vw; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  overflow: visible;
  /* border: 2px solid transparent; */
  border-bottom-left-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
  border-top-left-radius: var(--border-radius-small);
  border-top-right-radius: var(--border-radius-small);

  background-color: var(--gray-dark);
  overflow: hidden;
}
.id-circle {
  height: 2vw;
  width: 2vw;
  border-radius: 50%;
  background-color: var(--gray-light);
  margin-bottom: 1vw;
}
.id-red {
  background-color: var(--id-red);
}
.id-blue {
  background-color: var(--id-blue);
}
.id-yellow {
  background-color: var(--id-yellow);
}
.id-green {
  background-color: var(--id-green);
}

#fill-index-0 {
  filter: brightness(0) invert(1) brightness(0.1) sepia() hue-rotate(290deg)
    saturate(25);
}

#fill-index-1 {
  filter: brightness(0) invert(1) brightness(0.15) sepia() hue-rotate(150deg)
    saturate(30);
}

#fill-index-2 {
  filter: brightness(0) invert(1) brightness(0.5) sepia() hue-rotate(20deg)
    saturate(30);
}

#fill-index-3 {
  filter: brightness(0) invert(1) brightness(0.2) sepia() hue-rotate(100deg)
    saturate(30);
}

.playLink {
  height: 15%;
  width: 90%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.bottom-zone {
  width: 100%;
  flex: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1vw;
}

.b-start {
  justify-self: flex-end;
  height: 100%;
  background-color: var(--blue-start);
  border-radius: var(--border-radius-small);
  padding: 0.5vw 3vw 0vw 3vw;
  color: rgba(255, 255, 255, 1);
  font-size: 3vw;
  transition: 0.5s ease;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.b-start-inactive {
  justify-self: flex-end;
  height: 100%;
  border-radius: var(--border-radius-small);
  padding: 0.5vw 3vw 0vw 3vw;
  font-size: 3vw;
  transition: 0.5s ease;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(93, 93, 93, 0);
  color: rgba(198, 198, 198, 0.249);
}

.b-start:hover {
  transition: 0s ease;
  background-color: hsl(200, 70%, 15%);
  border: 0px solid;
}
.b-start:active {
  transition: 0s ease;

  color: grey;
  background-color: hsl(200, 70%, 05%);
  border: 0px solid;
}
.b-all-bots {
  height: 100%;
  /* flex: 0.2; */
  background-color: var(--gray-dark);
  border: 0px solid;
  /* border-radius: var(--border-radius-small); */
  padding: 0vw 0.5vw 0vw 0.5vw;

  /* margin-right: 0.25vw; */

  /* color: rgba(255, 255, 255, 0.2); */
  font-size: 1vw;
  transition: 0.5s ease;
  z-index: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-small);

  cursor: pointer;
}
.b-all-bots:hover {
  transition: 0s ease;
  background-color: hsl(200, 0%, 15%);
  border: 0px solid;
}
.b-all-bots:active {
  transition: 0s ease;

  background-color: hsl(200, 0%, 05%);
  border: 0px solid;
}
.center-my-children-small {
  width: 80px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.center-my-children-big {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#button-input-name {
  /* font-size: small; */
  padding: 0;
  margin: 0;
}

.b-dark-red {
  background-color: var(--id-red-dark);
}

.b-dark-blue {
  background-color: var(--id-blue-dark);
}

.b-dark-yellow {
  background-color: var(--id-yellow-dark);
}

.b-dark-green {
  background-color: var(--id-green-dark);
}

.b-dark {
  /* background-color: var(--gray-dark); */
  /* background-color: var(--magenta-active); */
  color: rgba(255, 255, 255, 1);
  transition: 0.5s ease;
}
.b-dark:hover {
  background-color: var(--gray-light);
  transition: 0.5s ease;
}
.b-dark:active {
  background-color: var(--orange-active);
  transition: 0.5s ease;
}

.b-oscuro {
  /* flex: 1; */
  position: relative;
  width: 100%;
  height: 12vw;
  /* margin-bottom: 1vw; */

  padding: 0;
  margin: 0;
  border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  font-size: x-large;
  /* overflow: hidden; */
  transition: var(--duration-hover) ease;
}

.b-oscuro > .button-input-emoji {
  position: absolute;
  top: -6vw;
  left: -0.5vw;
  /* height: 100%; */
  width: 100%;
  /* height: auto; */
  font-size: 12vw;
  margin: 0;
  padding: 0;
  overflow: visible;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transform: rotate(-30deg);
  /* filter: grayscale(1) contrast(1) brightness(100) invert(1) contrast(0.1)
    brightness(0.5); */
  filter: contrast(1) brightness(1);
  z-index: 0;
  /* transition: var(--duration-hover) ease-in-out; */
}

@keyframes emoji-animation {
  0% {
    /* transform: rotate(-30deg); */
  }
  50% {
    /* transform: rotate(-25deg); */
  }
  100% {
    /* transform: rotate(-30deg); */
  }
}

.b-oscuro:hover > .button-input-emoji {
  /* filter: contrast(0.5) brightness(0.2); */
  filter: contrast(0.5) brightness(0.2);
  /* animation: emoji-animation 0.3s ease-in-out both; */
  /* transition: var(--duration-hover) ease-in-out; */
}
.b-oscuro:active > .button-input-emoji {
  filter: contrast(0.5) brightness(0.4);
  /* transition: var(--duration-hover) ease-in-out; */
}
.b-oscuro:active > span {
  color: rgba(255, 255, 255, 0);
  transition: var(--duration-hover) ease-in-out;
}
.b-oscuro > span {
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 1.7vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0);
  z-index: 1;
}

.b-oscuro:hover > span {
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 1.7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  margin: -3vw;
  padding: 0;
  z-index: 1;
}
#input-sub {
  position: relative;
  font-size: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.b-black {
  background-color: hsl(200, 0%, 0%);
  color: rgba(255, 255, 255, 0.2);
  transition: var(--duration-hover) ease;
}
.b-black:hover {
  background-color: hsl(200, 0%, 8%);
  color: rgba(255, 255, 255, 0.5);
  transition: var(--duration-hover) ease;
}
.b-black:active {
  background-color: hsl(200, 0%, 12%);
  color: rgba(255, 255, 255, 1);
  transition: var(--duration-hover) ease;
}
.b-black > .button-input-emoji {
  color: rgba(255, 255, 255, 0);
  transition: var(--duration-hover) ease;
}

.start-title-wrapper {
  width: 100%;
  flex: 0.6;
  overflow: visible;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0; /* Allows shrinking below content's intrinsic size */
  min-height: 0; /* Allows the elements to shrink vertically */
}

.start-title {
  color: white;
  flex: 1;
  width: 45vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(18vw);
  filter: grayscale(1) brightness(3) brightness(0.5);
  z-index: 1;
  min-width: 0; /* Allows shrinking below content's intrinsic size */
  min-height: 0; /* Allows the elements to shrink vertically */
  overflow: hide;
}

.start-title-div {
  width: 100%;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0; /* Allows shrinking below content's intrinsic size */
  min-height: 0; /* Allows the elements to shrink vertically */
}
.start-title-div-img {
  height: 100%;
  object-fit: contain;
}

.start-title > h1 {
  flex: 1;
  /* font-size: 4vw; */
  text-align: center;
  margin: 0;
  width: 100%;
}

.start-title-init {
  transition: 1s ease-in-out;
  overflow: visible;
  padding-bottom: 2.5vw;
  /* padding-top: 1vw; */
  border: 1vw solid rgb(83, 83, 83);
  background-color: rgba(227, 227, 227, 0.258);
}
.start-title-init:hover {
  transition: 0.3s ease-in-out;
  filter: grayscale(1) brightness(1);
}
.start-title-init:active {
  transition: 0.3s ease-in-out;
  filter: grayscale(1) brightness(10);
}
.start-title-init > .start-title-h1 {
  overflow: visible;
  cursor: pointer;
  filter: brightness(0.5);
}
.start-title-init > .start-title-div > .start-title-div-img {
  overflow: visible;
}
.start-title-init > .start-title-div {
  width: auto;
  cursor: pointer;
}
.start-title-start {
  transition: 1s ease-in-out;
  animation: titleInitAnimation 2s ease-in-out both;
  overflow: visible;
  padding-bottom: 0vw;
  border: 0vw solid rgba(255, 255, 255, 0);
}
.start-title-start > .start-title-h1 {
  transition: 3s ease-in-out;
  animation: titleInitAnimationLetters 3s ease-in-out both;
}

@keyframes titleInitAnimation {
  0% {
    filter: grayscale(1) brightness(1);
  }
  100% {
    transform: translateY(0vw);
    filter: grayscale(0) brightness(1);
  }
}
@keyframes titleInitAnimationLetters {
  0% {
    filter: opacity(0);
  }
  70% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}

.start-title-corner-piece {
  position: absolute;
  /* width: 0.5vw;
  height: 0.5vw; */
  width: 1vw;
  height: 1vw;
  background-color: rgb(83, 83, 83);
  pointer-events: none;
}

.start-title-corner-piece:nth-of-type(5) {
  top: 0.5vw;
  left: 0.5vw;
}
.start-title-corner-piece:nth-of-type(2) {
  top: 0.5vw;
  right: 0.5vw;
}
.start-title-corner-piece:nth-of-type(3) {
  bottom: 0.5vw;
  left: 0.5vw;
}
.start-title-corner-piece:nth-of-type(4) {
  bottom: 0.5vw;
  right: 0.5vw;
}

.black-hiding-div {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 4;
  box-shadow: 0vw 0vw 15vw 15vw rgba(0, 0, 0, 1);
}
.black-hiding-div-init {
  transform: translateY(0%);
}
.black-hiding-div-start {
  animation: revealAnimation 5s ease-out both;
}

@keyframes revealAnimation {
  0% {
  }
  100% {
    transform: translateY(110vw);
  }
}

.player-char-image-name {
  position: absolute;
  bottom: calc(var(--distance-hover-neg));
  width: 100%;
  height: 100%;
  padding: 0 0 0.2vw 0;
  font-size: 1.7vw;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: var(--duration-hover) ease;
  color: transparent;
}
.player-choice:hover .player-char-image-name {
  color: white;
  transition: var(--duration-hover) ease;
}

.player-char {
  width: 100%;
  height: 100%;
  margin: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;

  transition: var(--duration-hover) ease;
}
.player-char:hover {
  transition: var(--duration-hover) ease;
  background-color: var(--gray-medium);
}
.player-char:active {
  transition: var(--duration-hover) ease;
  background-color: var(--gray-light);
  /* transform: translateY(-1%); */
}
.player-char > .startImageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: var(--duration-hover) ease;
  transform: translateY(0%);
  overflow: visible;
}
.player-char:hover > .startImageWrapper {
  transition: var(--duration-hover) ease;
  transform: translateY(var(--distance-hover-neg));
}
.player-char:active > .startImageWrapper {
  transition: var(--duration-hover) ease;
  transform: translateY(var(--distance-hover-neg));
}

.startCharacterId {
  align-self: center;
  z-index: 3;
}
.startImageNormal {
  margin-bottom: 2%;
  transform: scale(1);
  z-index: 0;
  image-rendering: pixelated;
}

.pixelated {
  image-rendering: pixelated;
}

.startImageInverse {
  margin-bottom: 2%;
  transform: scaleX(-1);
  z-index: 0;
  image-rendering: pixelated;
}

.loader {
  position: absolute;
  bottom: 0;
  left: 0;
  background: black;
  overflow: hidden;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.loading-table-wrapper {
  flex: 0.3;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading-table {
  position: relative;
  height: 100vw;
  image-rendering: pixelated;
}

.first-loader-p {
  width: 50vw;
  margin: 3vw 0vw 1.5vw 0vw;
  text-align: center;
  font-size: 1.5vw;
  color: white;
}

.second-loader-p {
  flex: 1;
  margin: 0vw 0vw 0vw 20vw;
  font-size: 1.5vw;
}

.download-screenshot {
  position: absolute;
  top: 15vw;
  left: 20vw;
  margin: 0% 0% 0% 0%;
  font-size: 0.7vw;

  cursor: pointer;
  z-index: 4;
}

.spinner-box {
  z-index: -1;
  flex: 1.5;
  overflow: visible;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* border: 10px solid lime; */
}
.spinner-rotate-x {
  position: relative;
  top: 8vw;
  width: 16vw;
  height: 16vw;

  /* animation: spinnerAnimation0 33s infinite ease; */
  animation: spinnerAnimation0 40.3s infinite ease;
  transform-style: preserve-3d;
  transition: 3s ease;

  overflow: visible;
  /* border: 10px solid lime; */
}
.spinner-rotate-y {
  width: 16vw;
  height: 16vw;

  /* animation: spinnerAnimation1 211s infinite ease; */
  animation: spinnerAnimation1 125.3s infinite ease;
  transform-style: preserve-3d;

  overflow: visible;
  /* border: 10px solid lime; */
}
.spinner {
  width: 16vw;
  height: 16vw;
  /* animation: spinnerAnimation2 23s infinite ; */
  animation: spinnerAnimation2 138.3s infinite ease;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: visible;
  /* border: 10px solid lime; */

  background-blend-mode: hue;
}

.cube_side_inside {
  background-color: white;
  height: 10vw;
  width: 10vw;
  border-radius: 0%;
  /* background-blend-mode: multiply; */
  background-blend-mode: hue;
}

.cube_side {
  background-blend-mode: hue;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.1);

  position: absolute;
  height: 10vw;
  width: 10vw;

  box-shadow: 0px 0px 0px 0px rgb(255, 255, 255),
    0px 0px 0px 0px rgb(255, 255, 255), 0px 0px 0px 0px rgb(255, 255, 255);

  filter: saturate(20%);
  border-radius: 0px;
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-5vw) rotateY(180deg);
  background-color: rgba(255, 255, 0, 0);
}
.spinner div:nth-of-type(1) > div {
  /* transform: translateZ(-80px) rotateY(180deg); */
  /* background-color: #e81224; */
  /* background-color: rgba(255, 255, 0, 1); */
  background-color: rgba(255, 255, 0, 0.3);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
  background-color: rgba(0, 255, 255, 0);
}
.spinner div:nth-of-type(2) > div {
  /* transform: rotateY(-270deg) translateX(50%);
    transform-origin: top right; */
  /* background-color: #0078d7; */
  /* background-color: rgba(0, 255, 255, 1); */
  background-color: rgba(0, 255, 255, 0.3);
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
  background-color: rgba(255, 0, 0, 0);
}
.spinner div:nth-of-type(3) > div {
  /* transform: rotateY(270deg) translateX(-50%);
    transform-origin: center left; */
  /* background-color: #fff100; */
  /* background-color: rgba(255, 0, 0, 1); */
  background-color: rgba(255, 0, 0, 0.3);
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
  background-color: rgba(255, 0, 255, 0);
}
.spinner div:nth-of-type(4) > div {
  /* transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center; */
  /* background-color: #16c60c; */
  /* background-color: rgba(255, 0, 255, 1); */
  background-color: rgba(255, 0, 255, 0.3);
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
  background-color: rgba(0, 255, 0, 0);
}
.spinner div:nth-of-type(5) > div {
  /* transform: rotateX(-90deg) translateY(50%);
    transform-origin: bottom center; */
  /* background-color: #886ce4; */
  /* background-color: rgba(0, 255, 0, 1); */
  background-color: rgba(0, 255, 0, 0.3);
}

.spinner div:nth-of-type(6) {
  transform: translateZ(5vw);
  background-color: rgba(0, 0, 255, 0);
  /* background-color: rgba(0, 0, 255, 0); */
}
.spinner div:nth-of-type(6) > div {
  /* transform: translateZ(80px); */
  /* background-color: #f7630c; */
  /* background-color: rgba(0, 0, 255, 1); */
  background-color: rgba(0, 0, 255, 0.3);
}

@keyframes spinnerAnimation0 {
  0% {
    transform: rotate(0deg) rotateX(-205deg) rotateY(25deg);
  }
  33% {
    transform: rotate(20deg) rotateX(-50deg) rotateY(250deg);
  }
  66% {
    transform: rotate(5deg) rotateX(-10deg) rotateY(25deg);
  }
  100% {
    transform: rotate(0deg) rotateX(-205deg) rotateY(25deg);
  }
}
@keyframes spinnerAnimation1 {
  0% {
    transform: rotate(38deg) rotateX(-25deg) rotateY(250deg);
  }
  33% {
    transform: rotate(20deg) rotateX(50deg) rotateY(250deg);
  }
  66% {
    transform: rotate(42deg) rotateX(-10deg) rotateY(25deg);
  }
  100% {
    transform: rotate(38deg) rotateX(-25deg) rotateY(250deg);
  }
}
@keyframes spinnerAnimation2 {
  0% {
    transform: rotate(10deg) rotateX(-10deg) rotateY(25deg);
  }
  33% {
    transform: rotate(10deg) rotateX(-10deg) rotateY(50deg);
  }
  66% {
    transform: rotate(42deg) rotateX(50deg) rotateY(125deg);
  }
  100% {
    transform: rotate(10deg) rotateX(-10deg) rotateY(25deg);
  }
}

.game-bar {
  position: absolute;
  bottom: 0.5vw;
  width: 100vw;
  height: 30vw;

  background-color: transparent;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  color: transparent;
}

.game-bar-time {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.game-bar-time > p {
  margin-bottom: -2vw;
}
.vs-span {
  /* font-size: 1.5vw; */
  padding-left: 0.2%;
  padding-right: 0.2%;
  /* border: 1px solid white; */
}

.dev-mode-div {
  position: absolute;
  top: 5vw;
  right: 5vw;
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5vw;
  font-weight: bold;
  text-align: center;
  padding: 2vw 3vw;
  border-radius: var(--border-radius-small);
}
.video-playback-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 1.5%;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
  padding: 3%;
}

.video-playback-super {
  /* border: 10px dashed red; */
  width: 100%;
  height: 65%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.video-playback {
  background-color: black;
  border-radius: var(--border-radius-small);
  border: 0.5vw solid white;
  box-shadow: 1vw 1vw 2vw 1vw rgba(0, 0, 0);
}
.video-playback-normal {
  filter: grayscale(0%);
}
.video-playback-gray {
  filter: grayscale(100%);
}
.replay {
  position: absolute;
  bottom: 25%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.3vw;
  text-align: center;
  padding: 10% 2% 1% 2%;
  pointer-events: none;
  animation: replayTextAnimation 1s infinite alternate;
  text-shadow: 0% 0% 1% rgba(0, 0, 0, 1);
  background-color: var(--magenta-active);
  border-bottom-left-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
  border: 0.5vw solid white;
  border-top: 0;
  box-shadow: 1vw 1vw 2vw 1vw rgba(0, 0, 0);
}

@keyframes replayTextAnimation {
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}

.replay-download {
  position: absolute;
  top: 6vw;
  /* width: 100%;
  height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1vw;
  /* font-weight: bold; */
  text-align: center;
  padding: 2vw 3vw;

  /* border-radius: var(--border-radius-small); */
  /* pointer-events: none; */
  cursor: pointer;
}
.keyboard-explainer-double {
  position: absolute;
  top: 20%;
  left: 25%;
  width: 50%;
  height: 25%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;

  border-radius: var(--border-radius-small);
  background-image: url(../public/images/keyboard_colors.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  overflow: visible;
  z-index: 1;
  /* opacity: 0.5; */

  cursor: pointer;
}
.keyboard-explainer-single {
  position: absolute;
  top: 20%;
  left: 25%;
  width: 50%;
  height: 25%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2vw;
  font-weight: bold;
  text-align: center;

  border-radius: var(--border-radius-small);
  background-image: url(../public/images/keyboard_color.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  overflow: visible;
  z-index: 1;
  /* opacity: 0.5; */

  cursor: pointer;
}

.keyboard-left-checkmark {
  position: absolute;
  top: -40%;
  left: 2%;
  width: 47%;
  height: 20%;
  padding: 5%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 2vw;
  font-weight: bold;
  text-align: center;

  border-radius: var(--border-radius-big);
}

.keyboard-right-checkmark {
  position: absolute;
  top: -40%;
  right: 2%;
  width: 47%;
  height: 20%;
  padding: 5%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-size: 2vw;
  font-weight: bold;
  text-align: center;

  border-radius: var(--border-radius-big);
}

.keyboard-right-checkmark > span,
.keyboard-left-checkmark > span {
  font-size: 1vw;
  font-weight: normal;
}

.small {
  font-size: 0.8vw;
}

.small-spinner {
  position: relative;
  width: 20%;
  height: 20%;
  background-color: rgba(0, 0, 0, 1);
  border: 1% solid white;
  border-radius: 50%;
  width: 10%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
  margin: 0 3% 0 3%;
}

.ss-blue {
  border: 1vw solid #0078d7;
  background-color: #0078d7;
  animation-delay: 0.5s;
  animation: spinnerAnimation 3s infinite linear;
  animation-delay: 1.5s;
}
.ss-red {
  border: 1vw solid #e81224;
  background-color: #e81224;
  animation: spinnerAnimation 3s infinite linear;
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg) rotateX(-205deg) rotateY(25deg);
  }
  33% {
    transform: rotate(20deg) rotateX(-50deg) rotateY(250deg);
  }
  66% {
    transform: rotate(5deg) rotateX(-10deg) rotateY(25deg);
  }
  100% {
    transform: rotate(0deg) rotateX(-205deg) rotateY(25deg);
  }
}

.input-group {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* border: 1px dashed red; */
  /* padding: 0.5vw; */
  background-color: var(--gray-dark);
  border-radius: 1vw;
}

.input-group-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-size: 1.2vw; */
  margin: 0 1.5vw 0 1.5vw;
}

#dice {
  transform-origin: 50% 64%;
  font-size: 4vw;
  transform: rotate(0deg);
  filter: grayscale(1) contrast(3) brightness(0.5);
  background-color: transparent;
  transition: 1s ease;
  overflow: visible;
}
#dice:hover {
  filter: grayscale(1) contrast(3);
  transition: 1s cubic-bezier(0.3, 0.72, 0.48, 0.98);
}
#dice:active {
  transform: rotate(1440deg);
  filter: grayscale(1) contrast(3);
  transition: 0s ease;
}

table {
  width: 99%;
  /* margin-right: 10px; */
}
td,
th {
  /* min-width: 100px; */
  /* font-size: 0.8vw; */
}
#title {
  font-size: 0.8vw;
}

.td-right {
  min-width: 80px;
  font-size: 1.5vw;
  /* font-size: 1.5vw;
  font-weight: bold; */
  text-align: right;
  /* padding: 0.5vw 0.5vw; */
  /* border: 1px solid white; */
  /* border-radius: var(--border-radius-small); */
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.td-left {
  min-width: 80px;
  font-size: 0.8vw;
  /* font-size: 1.5vw;
  font-weight: bold; */
  text-align: left;
  /* padding: 0.5vw 0.5vw; */
  /* border: 1px solid white; */
  /* border-radius: var(--border-radius-small); */
  /* background-color: rgba(0, 0, 0, 0.5); */
}

#td-info {
  font-size: 0.8vw;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

#td-odd {
  background-color: rgba(0, 0, 0, 0.8);
  height: 3.5vw;
}
#td-even {
  background-color: rgba(0, 0, 0, 0.5);
  height: 3.5vw;
}

td > div:nth-child(2) {
  margin-top: -0.5vw;
  margin-right: 0.2vw;
  margin-left: 0.2vw;
  font-size: 1.37vw;
  /* font-family: 'Courier New', Courier, monospace; */
}

#tr-header {
  height: 3.5vw;
  background-color: rgb(0, 0, 0, 0);
}

#show-all {
  position: absolute;
  bottom: 5%;
  left: 5%;
  padding: 1%;
  border: 3px solid rgb(169, 126, 134);
  border-radius: var(--border-radius-small);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 1);
  color: rgb(169, 126, 134);
  cursor: pointer;
}

.show-all-show {
  background-color: black;

  color: white;
}

.show-all-show:hover {
  color: white;
  background-color: rgb(36, 27, 29);
}
.show-all-show:active {
  background-color: rgb(27, 20, 21);
}

.show-all-hide {
  background-color: rgb(120, 92, 97);
  color: white;
}
.show-all-hide:hover {
  background-color: rgb(119, 89, 94);
}
.show-all-hide:active {
  background-color: rgb(32, 28, 28);
}

.neural-network-train-status {
  z-index: 4;
  width: 100%;
  height: 33%;
  position: absolute;
  bottom: 0%;
  left: 0%;
  padding: 1%;
  /* border: 3px solid white; */
  border-top: 5px solid white;
  background-color: black;
  /* border-radius: var(--border-radius-small); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.neural-network-train-status > span,
.neural-network-train-top > span {
  flex: 1;
  font-size: 1.5vw;
}

.neural-network-train-top {
  width: 80%;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0%;
  margin: 0%;
}

.neural-network-train-bottom {
  width: 80%;
  flex: 1.2;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0%;
  margin: 0%;
}

.mobile-warning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3vw;
  font-weight: bold;
  text-align: center;
  padding: 2vw 3vw;
  border-radius: var(--border-radius-small);
  pointer-events: none;
}

.mobile-warning > img {
  width: 80%;
  height: 100%;
  object-fit: contain;
}
.mobile-warning > span {
  padding-bottom: 3%;
}

#niemo-games {
  font-size: 2.88vw;
  margin-bottom: -1.8vw;
}

.snes {
  height: 10vw;
  margin-bottom: 1vw;
}
